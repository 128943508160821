.DateInput, .DateInput_1, .SingleDatePickerInput, .SingleDatePickerInput_1, .SingleDatePickerInput__withBorder, .SingleDatePickerInput__withBorder_2, .SingleDatePicker, .SingleDatePicker_1 {
  all: unset; }

.dtpInputContainer {
  flex: 18; }

.dtpBtnContainer {
  flex: 1; }
  .dtpBtnContainer > button {
    padding: .375rem .60rem; }

.DateInput_input {
  all: unset;
  display: none; }

.CalendarDay:hover {
  background: #61bcf8;
  border: 1px solid #61bcf8;
  color: white; }

.CalendarDay__selected {
  background: #0a90e9;
  border: 1px double #0a90e9;
  color: white; }

.CalendarDay__selected_span {
  background: #30a7f6;
  border: 1px double #0a90e9;
  color: white; }

.CalendarDay__selected:hover, .CalendarDay__selected:active {
  background: #30a7f6;
  border: 1px solid #30a7f6;
  color: white; }

.CalendarDay__hovered_span:hover, .CalendarDay__hovered_span {
  background: #61bcf8; }

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #0a90e9; }

.SingleDatePicker_picker {
  z-index: 10000; }

.actions-container {
  padding: 0px 22px 11px !important;
  display: flex;
  justify-content: space-around; }

.actions-container button {
  position: relative !important;
  height: 100% !important;
  text-align: center !important;
  margin-right: 8px !important;
  box-sizing: border-box !important;
  cursor: pointer !important;
  background: none !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-radius: 5px;
  border-image: initial !important;
  padding: 4px 12px !important;
  font: inherit inherit inherit inherit inherit inherit inherit inherit !important;
  overflow: visible !important; }

.actions-container > .btn-one {
  background: var(--color4, #6a6c6f) !important;
  color: white !important; }
  .actions-container > .btn-one:hover {
    background: var(--color4-lighten5, #6a6c6f) !important; }

.actions-container > .btn-two {
  background: var(--color1-darken10, #62cb31) !important;
  color: white !important; }
  .actions-container > .btn-two:hover {
    background: var(--color1-darken5, #62cb31) !important; }

.actions-container > .btn-clear {
  background: #ff3333 !important;
  color: white !important; }
  .actions-container > .btn-clear:hover {
    background: #ff4d4d !important; }
